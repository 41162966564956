import moment from "moment";

const sortByMonth = (item) => {
  console.log(item, "asdasdasdasd");
  return moment(item.day, "YYYY-MM-DD").format("YYYY-MM-DD");
};

const sortByWeek = (item) => {
  const weekStartDate = moment(item.week, "YYYY-WW").startOf("isoweek");
  const weekEndDate = moment(item.week, "YYYY-WW").endOf("isoweek");
  const weekName = `${weekStartDate.format("MMMM D")} - ${weekEndDate.format("MMMM D, YYYY")}`;
  return weekName;
};

const sortByDay = (item) => {
  const dayDate = moment(item.day, "YYYY-MM-DD", true); // Strict parsing
  if (!dayDate.isValid()) {
    console.error("Invalid date:", item.day);
    return "Invalid Date";
  }

  return dayDate.format("dddd, MMMM D, YYYY");
};
function filterAndFormat(
  transaction = {
    completedData: [],
    pendingData: [],
    failedData: [],
    stopedData: [],
  },
  type
) {
  const allFiltered = [
    ...transaction?.completedData,
    ...transaction?.pendingData,
    ...transaction?.failedData,
    ...transaction?.stopedData,
  ]
    .filter((item) => item?.day || item?.month || item?.week) // Ensure valid data
    .map((item) =>
      type === "month" ? sortByMonth(item) : type === "week" ? sortByWeek(item) : sortByDay(item)
    );

  return [...new Set(allFiltered)];
}

function calculatePercentageChange(arr = [], allData, status) {
  // Sum function to handle optional chaining and sum the counts
  const sumCount = (dataArray) => {
    return Array.isArray(dataArray)
      ? dataArray.reduce((sum, item) => sum + (Number(item?.count) || 0), 0)
      : 0;
  };

  // Sum function to handle optional chaining and sum the totalAmount
  const sumTotalAmount = (dataArray) => {
    return Array.isArray(dataArray)
      ? dataArray.reduce((sum, item) => sum + (Number(item?.totalAmount) || 0), 0)
      : 0;
  };

  // If status is successTransacted, calculate based on totalAmount
  if (status === "successTransacted") {
    const arrTotalAmount = sumTotalAmount(arr);
    const allDataTotalAmount =
      sumTotalAmount(allData?.completedData) +
      sumTotalAmount(allData?.failedData) +
      sumTotalAmount(allData?.pendingData) +
      sumTotalAmount(allData?.stopedData);

    // Avoid division by zero
    if (allDataTotalAmount === 0) {
      return "0.00%";
    }

    // Calculate percentage change based on totalAmount
    const percentageChange = (arrTotalAmount / allDataTotalAmount) * 100;
    return `${percentageChange.toFixed(2)}%`;
  }

  // Otherwise, calculate based on counts
  const allDataCount =
    sumCount(allData?.completedData) +
    sumCount(allData?.failedData) +
    sumCount(allData?.pendingData) +
    sumCount(allData?.stopedData);

  // Handle case where allDataCount is zero (to avoid division by zero)
  if (allDataCount === 0) {
    return "0.00%";
  }

  // Calculate the count from the provided array 'arr'
  const arrCount = arr?.reduce((sum, item) => sum + (Number(item?.count) || 0), 0) || 0;

  // Calculate the percentage change based on count
  const percentageChange = (arrCount / allDataCount) * 100;

  // Return the percentage with two decimal places
  return `${percentageChange.toFixed(2)}%`;
}

// Function to get the last 30 days' dates (for "month" logic)
function getLast30DaysDates() {
  const dates = [];
  const currentDate = moment();

  for (let i = 0; i < 30; i++) {
    const date = moment(currentDate).subtract(i, "days");
    dates.push(date.format("YYYY-MM-DD")); // Format as year-month-day
  }

  return dates.reverse(); // Ensure the order is from oldest to newest
}

// Function to get the last 7 days' dates (for "week" logic)
function getLast7DaysDates() {
  const dates = [];
  const currentDate = moment();

  for (let i = 0; i < 7; i++) {
    const date = moment(currentDate).subtract(i, "days");
    dates.push(date.format("YYYY-MM-DD")); // Format as day.month.year
  }

  return dates.reverse(); // Ensure the order is from oldest to newest
}

// Function to get current day's hourly dates (for "day" logic)
function getCurrentDayHours() {
  const startDate = moment().startOf("day"); // Start of the current day (00:00)
  const currentDate = moment(); // Current time
  const hoursDates = [];

  while (startDate.isBefore(currentDate)) {
    const formattedDate = startDate.format("YYYY-MM-DD HH:00");
    hoursDates.push(formattedDate);
    startDate.add(1, "hour"); // Increment by one hour
  }

  return hoursDates;
}

function getYearAgoMonths() {
  const currentDate = moment(); // Current date
  const yearAgoDate = moment().subtract(1, "year"); // Date exactly 1 year ago
  const months = [];

  while (yearAgoDate.isBefore(currentDate, "month") || yearAgoDate.isSame(currentDate, "month")) {
    const formattedMonth = yearAgoDate.format("YYYY-MM");
    months.push(formattedMonth);
    yearAgoDate.add(1, "month"); // Increment by one month
  }

  return months;
}

function getAll(date) {
  const inputDate = moment(date); // Given date
  const currentDate = moment(); // Current date
  const months = [];

  while (inputDate.isBefore(currentDate, "month") || inputDate.isSame(currentDate, "month")) {
    const formattedMonth = inputDate.format("YYYY-MM");
    months.push(formattedMonth);
    inputDate.add(1, "month"); // Increment by one month
  }

  return months;
}

function getDate(type, data) {
  switch (type) {
    case "all":
      return getAll(data);
    case "year":
      return getYearAgoMonths();
    case "month":
      return getLast30DaysDates();
    case "week":
      return getLast7DaysDates();
    case "day":
      return getCurrentDayHours();
    default:
      return [];
  }
}

const generateChartData = ({ data, label, color, renderKey, type }) => {
  const datasets = {
    label,
    color,
    dataPercentage: data?.transaction?.all?.[renderKey]?.map((item) => item?.percentage),
    dataAmount: data?.transaction?.all?.[renderKey]?.map((item) => item?.totalAmount),
    dataCount: data?.transaction?.all?.[renderKey]?.map((item) => item?.count),
  };

  return { labels: getDate("all", data?.transaction.firstDate), datasets };
};

export {
  getDate,
  filterAndFormat,
  getLast30DaysDates,
  getLast7DaysDates,
  calculatePercentageChange,
  getCurrentDayHours,
  generateChartData,
};
