import SoftBox from "components/SoftBox";
import React from "react";
import PropTypes from "prop-types";
import styles from "./index.module.css";
import SoftTypography from "components/SoftTypography";
import { Tooltip } from "@mui/material";
import { isObject } from "lodash";

const CustomTableItem = ({ logo, data, errorData, type, description = "" }) => {
  const keys = Object.keys(data || {});
  const checkNumber = (char) => {
    if (!isNaN(+char)) {
      return <span style={{ color: "black" }}>{char}</span>;
    } else {
      return <span style={{ color: "red", fontWeight: "bold" }}>{char}</span>;
    }
  };

  return (
    <SoftBox>
      <SoftBox gap={1} className={styles.tableheaderLogo} alignItems="center">
        <SoftTypography>{description}</SoftTypography>
        <img src={logo} width={"160px"} />
      </SoftBox>
      <SoftBox>
        {keys?.map(
          (key, index) =>
            !isObject(data?.[`${key}`]) && (
              <SoftBox key={index} className={styles.childrenItemBox}>
                <SoftBox>{key}</SoftBox>
                <Tooltip title={data?.[`${key}`]}>
                  <SoftBox>
                    <SoftTypography
                      sx={{
                        color: data?.[`${key}`] == errorData?.code && "red !important",
                        fontWeight: data?.[`${key}`] == errorData?.code && "bold",
                      }}
                    >
                      {data?.[`${key}`]}
                    </SoftTypography>
                  </SoftBox>
                </Tooltip>
              </SoftBox>
            )
        )}
        {keys.map((key, index) => {
          return (
            isObject(data?.[`${key}`]) && (
              <SoftBox key={index} className={styles.itemElemetTitle}>
                <SoftBox width={"100%"}>
                  <SoftBox className={styles.childrenItemTitleBox}>{key}</SoftBox>
                  {Object.keys(data?.[`${key}`])?.map((item, index) => {
                    return (
                      <SoftBox key={index} className={styles.childrenItemBox}>
                        <SoftBox>
                          {(errorData?.errorData?.some((error) => item.includes(error?.key)) && (
                            <Tooltip
                              title={
                                errorData?.errorData.find((error) => item.includes(error?.key))
                                  ?.message || ""
                              }
                            >
                              <SoftTypography
                                className={
                                  errorData?.errorData?.some((error) => item === error?.key)
                                    ? styles.ItemKeyFailed
                                    : errorData?.errorData.some(
                                        (error) => error?.key === "?xml@_version"
                                      ) &&
                                      item === "@_version" &&
                                      key === "?xml" &&
                                      type === "fora"
                                    ? styles.ItemKeyFailed
                                    : errorData?.errorData.some(
                                        (error) => error?.key === "?xml@_encoding"
                                      ) &&
                                      item === "@_encoding" &&
                                      key === "?xml" &&
                                      type === "fora"
                                    ? styles.ItemKeyFailed
                                    : ""
                                }
                              >
                                {item}
                              </SoftTypography>
                            </Tooltip>
                          )) || (
                            <Tooltip
                              title={
                                errorData?.errorData.find((error) =>
                                  `${key}${item}`.includes(error?.key)
                                )?.message || ""
                              }
                            >
                              <SoftTypography
                                className={
                                  errorData?.errorData.some(
                                    (error) => error?.key === "?xml@_version"
                                  ) &&
                                  item === "@_version" &&
                                  key === "?xml" &&
                                  type === "fora"
                                    ? styles.ItemKeyFailed
                                    : errorData?.errorData.some(
                                        (error) => error?.key === "?xml@_encoding"
                                      ) &&
                                      item === "@_encoding" &&
                                      key === "?xml" &&
                                      type === "fora"
                                    ? styles.ItemKeyFailed
                                    : ""
                                }
                              >
                                {item}
                              </SoftTypography>
                            </Tooltip>
                          )}
                        </SoftBox>
                        <Tooltip title={data?.[`${key}`]?.[`${item}`]}>
                          <SoftBox sx={{ cursor: "pointer" }}>
                            <SoftTypography
                              className={
                                data?.[`${key}`]?.[`${item}`] == errorData?.code
                                  ? styles.ItemKeyFailed
                                  : errorData?.errorData.some(
                                      (error) => error?.key === "?xml@_version"
                                    ) &&
                                    item === "@_version" &&
                                    key === "?xml" &&
                                    type === "fora"
                                  ? styles.ItemKeyFailed
                                  : errorData?.errorData.some(
                                      (error) => error?.key === "?xml@_encoding"
                                    ) &&
                                    item === "@_encoding" &&
                                    key === "?xml" &&
                                    type === "fora"
                                  ? styles.ItemKeyFailed
                                  : errorData?.errorData.some((error) => error?.key === item)
                                  ? styles.ItemKeyFailed
                                  : ""
                              }
                            >
                              {(errorData?.errorData?.some((error) => item.includes(error?.key)) &&
                                item !== "@_version" &&
                                String(data?.[`${key}`]?.[`${item}`])
                                  ?.split("")
                                  ?.map((char, index) => (
                                    <React.Fragment key={index}>{checkNumber(char)}</React.Fragment>
                                  ))) ||
                                data?.[`${key}`]?.[`${item}`]}
                            </SoftTypography>
                          </SoftBox>
                        </Tooltip>
                      </SoftBox>
                    );
                  })}
                </SoftBox>
              </SoftBox>
            )
          );
        })}
      </SoftBox>
    </SoftBox>
  );
};

CustomTableItem.propTypes = {
  logo: PropTypes.any,
  data: PropTypes.any,
  type: PropTypes.string,
  errorData: PropTypes.any,
  description: PropTypes.any,
};

export default CustomTableItem;
