import { useCallback, useContext, useEffect } from "react";

// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";
import { getProfile } from "http/userApi";
import avatar from "assets/images/avatar.png";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// React examples
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";

// Custom styles for the Sidenav
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";

// React context
import { useSoftUIController, setMiniSidenav, setUserRole, AuthContext } from "context";

import SoftButton from "components/SoftButton";
import ILogOut from "examples/Icons/ILogOut";
import { Avatar, Box, Tooltip } from "@mui/material";
import { useQuery } from "react-query";

function Sidenav({ color, brand, brandName, routes, ...rest }) {
  const { isLoading, data: user } = useQuery("userprofile", () => getProfile());

  useEffect(() => {
    if (!isLoading && user) {
      setUserRole(dispatch, user?.roles[0]);
    }
  }, [isLoading, user]);

  const [controller, dispatch] = useSoftUIController();
  const authContext = useContext(AuthContext);

  const { miniSidenav, transparentSidenav } = controller;
  const location = useLocation();
  const { pathname } = location;
  const collapseName = pathname.split("/").slice(1)[0];

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  const handleSignOut = useCallback(() => {
    authContext.logout();
  }, []);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(({ type, name, icon, title, noCollapse, key, route, href }) => {
    if (
      user?.roles[0] === "admin" &&
      (route?.includes("/basicAuth") || route?.includes("/users"))
    ) {
      return;
    }
    if (user?.roles[0] === "admin" && type?.includes("title") && title?.includes("User Pages")) {
      return;
    }
    let returnValue;

    if (type === "collapse") {
      returnValue = href ? (
        <Link
          href={href}
          key={key}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: "none" }}
        >
          <SidenavCollapse
            color={color}
            name={name}
            icon={icon}
            active={key === collapseName}
            noCollapse={noCollapse}
          />
        </Link>
      ) : (
        <NavLink to={route} key={key}>
          <SidenavCollapse
            color={color}
            key={key}
            name={name}
            icon={icon}
            active={key === collapseName}
            noCollapse={noCollapse}
          />
        </NavLink>
      );
    } else if (type === "title") {
      returnValue = (
        <SoftTypography
          key={key}
          display="block"
          variant="caption"
          fontWeight="bold"
          textTransform="uppercase"
          opacity={0.6}
          pl={3}
          mt={2}
          mb={1}
          ml={1}
        >
          {title}
        </SoftTypography>
      );
    } else if (type === "divider") {
      returnValue = <Divider key={key} />;
    }

    return returnValue;
  });

  return (
    <SidenavRoot {...rest} variant="permanent" ownerState={{ miniSidenav }}>
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"space-between"}
        height={"100%"}
      >
        <Box>
          <SoftBox pt={3} pb={1} px={4} textAlign="center">
            <SoftBox
              display={{ xs: "block", xl: "none" }}
              position="absolute"
              top={0}
              right={0}
              p={1.625}
              onClick={closeSidenav}
              sx={{ cursor: "pointer" }}
            >
              <SoftTypography variant="h6" color="secondary">
                <Icon sx={{ fontWeight: "bold" }}>close</Icon>
              </SoftTypography>
            </SoftBox>
            <SoftBox component={NavLink} to="/" display="flex" alignItems="center">
              {brand && <SoftBox component="img" src={brand} alt="Brand" width="100%" />}
            </SoftBox>
          </SoftBox>
          <Divider />
          <List>{renderRoutes}</List>
        </Box>
        <Box pb={3} px={4} textAlign="center">
          <Box pb={3} display="flex" justifyContent={"space-between"} alignItems="center">
            <Avatar
              sx={{
                cursor: "pointer",
                height: 25,
                width: 25,
              }}
              src={avatar}
            />
            <Tooltip placement="top" title={user?.email}>
              <SoftTypography color="text" size="sm" fontWeight="bold" style={{ fontSize: "13px" }}>
                {!isLoading &&
                  (user?.email?.length > 20 ? `${user.email.slice(0, 20)}...` : user?.email)}{" "}
              </SoftTypography>
            </Tooltip>
          </Box>

          <SoftButton variant="gradient" color="warning" fullWidth onClick={handleSignOut}>
            <SoftBox>
              {/* <AccountPopover
                anchorEl={accountPopover.anchorRef.current}
                open={accountPopover.open}
                onClose={accountPopover.handleClose}
              /> */}
            </SoftBox>
            <SoftTypography variant="p" color="white" mr={1}>
              Sign out
            </SoftTypography>
            <ILogOut color="white" size="18px" />
          </SoftButton>
        </Box>
      </Box>
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "warning",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
