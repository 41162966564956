import React from "react";
import PropTypes from "prop-types";
import { Autocomplete, TextField } from "@mui/material";

const CompliteLimit = ({ value, onChangeCompliteValue }) => {
  return (
    <Autocomplete
      value={value}
      onChange={onChangeCompliteValue}
      defaultValue={value}
      aria-required
      disablePortal
      disableClearable
      sx={{ width: 80 }}
      options={[5, 10, 20]}
      renderInput={(params) => <TextField {...params} placeholder="Limit" />}
    />
  );
};

CompliteLimit.propTypes = {
  onChangeCompliteValue: PropTypes.func,
  value: PropTypes.any,
  isLoading: PropTypes.bool,
};

export default CompliteLimit;
