import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { Card, Skeleton } from "@mui/material";
import PropTypes from "prop-types";

import SoftBox from "components/SoftBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { getOneTransaction } from "http/transactionApi";
import SoftTypography from "components/SoftTypography";
import foralogo from "assets/images/fora-bank2.png";
import tpmlogo from "assets/images/textnpayme2.png";
import EasyPaylogo from "assets/images/easypay2.png";
import CustomTableItem from "components/CustomTableItem";
import { omit } from "lodash";

const TransactionUUID = () => {
  const { uuid } = useParams();
  useEffect(() => {}, [uuid]);
  const { isLoading, data: transaction } = useQuery(["transactionbyUuid", [uuid]], () =>
    getOneTransaction(uuid)
  );

  const transactionsKeys = Object.keys(
    omit(transaction?.details, [
      "foraNthCheck",
      "EasyPayNthCheck",
      "tpmNthCheck",
      "foraPayment",
      "EasyPayPayment",
      "tpmPayment",
      "EasyPayNthCheckRequest",
      "EasyPayPaymentRequest",
    ]) || {}
  );

  return (
    <DashboardLayout>
      <DashboardNavbar status={transaction?.status} />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            {isLoading ? (
              <SoftBox
                display="flex"
                justifyContent="space-between"
                alignItems="flex-start"
                width={"100%"}
                px={3}
              >
                <SoftBox width={"30%"}>
                  <Skeleton height={"80vh"} width={"100%"} />
                </SoftBox>
                <SoftBox width={"30%"}>
                  <Skeleton height={"50vh"} width={"100%"} />
                </SoftBox>
                <SoftBox width={"30%"}>
                  <Skeleton height={"40vh"} width={"100%"} />
                </SoftBox>
              </SoftBox>
            ) : (
              <SoftBox display="flex" flexDirection="column" p={2}>
                {Object.keys(transaction?.details).includes("foraNthCheck") && (
                  <React.Fragment>
                    <SoftBox
                      // mx={2}
                      py={1.5}
                      px={1.5}
                      bgColor={
                        (transaction?.details?.error && "error") ||
                        "linear-gradient(310deg, #ffac30, #fbcf33)"
                      }
                      borderRadius="lg"
                      coloredShadow="info"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <SoftTypography variant="h6" color="white">
                        Nmt Check
                      </SoftTypography>
                    </SoftBox>
                    <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      width={"100%"}
                      gap={1}
                      sx={{
                        overflowX: "scroll",
                        width: "100%",
                        paddingBottom: "20px",
                        paddingTop: "20px",
                        scrollbarwidth: "thin",
                        "&::-webkit-scrollbar": {
                          width: "0.4em",
                          height: "7px",
                          borderRadius: "5px",
                        },
                        "&::-webkit-scrollbar-track": {
                          background: "#f1f1f1",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          backgroundColor: "#888",
                          borderRadius: "5px",
                        },
                        "&::-webkit-scrollbar-thumb:hover": {
                          background: "#555",
                        },
                      }}
                    >
                      <CustomTableItem
                        logo={foralogo}
                        errorData={transaction?.details?.error}
                        data={transaction?.details?.foraNthCheck}
                        type={"fora"}
                        description={"Request"}
                      />
                      <CustomTableItem
                        logo={tpmlogo}
                        data={transaction?.details?.EasyPayNthCheckRequest}
                        type={"tpm"}
                        description={"Request"}
                      />
                      <CustomTableItem
                        logo={EasyPaylogo}
                        errorData={transaction?.details?.error}
                        data={transaction?.details?.EasyPayNthCheck}
                        type={"EasyPay"}
                        description={"Response"}
                      />
                      <CustomTableItem
                        logo={tpmlogo}
                        errorData={transaction?.details?.error}
                        data={transaction?.details?.tpmNthCheck}
                        type={"tpm"}
                        description={"Response"}
                      />
                    </SoftBox>
                  </React.Fragment>
                )}
                {Object.keys(transaction?.details).includes("foraPayment") && (
                  <SoftBox mt={3}>
                    <SoftBox
                      // mx={2}
                      mb={2}
                      py={1.5}
                      px={1.5}
                      bgColor={
                        (transaction?.details?.error && "error") ||
                        "linear-gradient(310deg, #ffac30, #fbcf33)"
                      }
                      borderRadius="lg"
                      coloredShadow="info"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <SoftTypography variant="h6" color="white">
                        Payment
                      </SoftTypography>
                    </SoftBox>
                    <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      width={"100%"}
                      gap={1}
                      sx={{
                        overflowX: "scroll",
                        width: "100%",
                        paddingBottom: "20px",
                        paddingTop: "20px",
                        scrollbarwidth: "thin",
                        "&::-webkit-scrollbar": {
                          width: "0.4em",
                          height: "7px",
                          borderRadius: "5px",
                        },
                        "&::-webkit-scrollbar-track": {
                          background: "#f1f1f1",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          backgroundColor: "#888",
                          borderRadius: "5px",
                        },
                        "&::-webkit-scrollbar-thumb:hover": {
                          background: "#555",
                        },
                      }}
                    >
                      <CustomTableItem
                        logo={foralogo}
                        errorData={transaction?.details?.error}
                        data={transaction?.details?.foraPayment}
                        type={"fora"}
                        description={"Request"}
                      />
                      <CustomTableItem
                        logo={tpmlogo}
                        data={transaction?.details?.EasyPayPaymentRequest}
                        type={"tpm"}
                        description={"Request"}
                      />
                      <CustomTableItem
                        logo={EasyPaylogo}
                        data={transaction?.details?.EasyPayPayment}
                        type={"EasyPay"}
                        description={"Response"}
                      />
                      <CustomTableItem
                        logo={tpmlogo}
                        errorData={transaction?.details?.error}
                        data={transaction?.details?.tpmPayment}
                        type={"tpm"}
                        description={"Response"}
                      />
                    </SoftBox>
                  </SoftBox>
                )}
                {(transaction?.status === "failed" && (
                  <SoftBox>
                    <SoftBox display="flex" alignItems="center" gap={1}>
                      {transaction?.details?.error?.code && (
                        <SoftTypography component="span" variant="body2">
                          code:
                        </SoftTypography>
                      )}
                      <SoftTypography
                        component="span"
                        color="error"
                        variant="body2"
                        fontWeight="bold"
                      >
                        {transaction?.details?.error?.code}
                      </SoftTypography>
                    </SoftBox>
                    {transaction?.details?.error?.errorData?.map((item, index) => (
                      <SoftBox key={index} display="flex" alignItems="center" gap={1}>
                        <SoftTypography component="span" variant="body2">
                          {item.key}
                        </SoftTypography>
                        <SoftTypography component="span" variant="body2">
                          -
                        </SoftTypography>
                        <SoftTypography component="span" color="error" variant="body2">
                          {item.message}
                        </SoftTypography>
                      </SoftBox>
                    ))}
                  </SoftBox>
                )) ||
                  transactionsKeys?.map((transactionsKey) => (
                    <SoftBox key={transactionsKey}>
                      <SoftTypography component="span" variant="body2">
                        {(transactionsKey == "createdAt" &&
                          `created - ${moment(transaction?.details[transactionsKey]).format(
                            "DD.MM.YYYY, HH:mm"
                          )}`) ||
                          `${transactionsKey.split("_").join(" ").split("@").join("")} - ${
                            transaction?.details[transactionsKey]
                          }`}
                      </SoftTypography>
                    </SoftBox>
                  ))}
              </SoftBox>
            )}
          </Card>
        </SoftBox>
      </SoftBox>
    </DashboardLayout>
  );
};

TransactionUUID.propTypes = {
  details: PropTypes.object,
  uuid: PropTypes.string,
};

export default TransactionUUID;
