import axios from "axios";

const $host = axios.create({
  baseURL: "https://gtw.textnpay.me/",
});

const $authHost = axios.create({
  baseURL: "https://gtw.textnpay.me/",
});

const authInterceptor = async (config) => {
  config.headers.authorization = `Bearer ${localStorage.getItem("token")}`;
  return config;
};
const onResponseFail = (error) => {
  console.log(error, "errorerrorerror");

  const status = error.status || error.response.status;
  if (status === 401 || (status === 417 && error.message === "jwt expired")) {
    localStorage.removeItem("token");
  }

  return Promise.reject(error);
};

$authHost.interceptors.request.use(authInterceptor, onResponseFail);

$authHost.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      (error.response.data.message === "jwt expired" && error.response.data.statusCode === 417) ||
      (error.response.data.message === "invalid token" && error.response.data.statusCode === 417) ||
      (error.response.data.message === "jwt malformed" && error.response.data.statusCode === 417)
    ) {
      localStorage.removeItem("token");
      window.location.reload();
    }

    return Promise.reject(error); // Ensure the error is propagated
  }
);

export { $host, $authHost };
