import React from "react";
import PropTypes from "prop-types";
import * as moment from "moment";
import CsvDownloader from "react-csv-downloader";

import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import IXlsx from "examples/Icons/IXlsx";

const CSVDownloadBtn = ({ datas, downloadLoading }) => {
  return (
    <CsvDownloader
      filename={`Fora_to_EasyPay_Transactions_${moment(new Date()).format("YYYY_MM_DD_HHmmss")}`}
      extension=".csv"
      separator=";"
      wrapColumnChar="'"
      datas={datas}
      disabled={downloadLoading}
    >
      <SoftButton
        disabled={downloadLoading}
        variant="gradient"
        color="success"
        display="flex"
        alignItems="center"
      >
        <SoftTypography component="label" variant="caption" fontWeight="bold" color="white" mr={1}>
          DOWNLOAD BY
        </SoftTypography>
        <IXlsx size={"30px"} />
      </SoftButton>
    </CsvDownloader>
  );
};

CSVDownloadBtn.propTypes = {
  datas: PropTypes.any,
  downloadLoading: PropTypes.bool,
};

export default CSVDownloadBtn;
