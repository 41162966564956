import { Skeleton } from "@mui/material";
import SoftBox from "components/SoftBox";
import { generateData } from "utils/tableColumnData";

const data = () => {
  return {
    columns: [
      { name: "pay_uno", accessor: "source", align: "center" },
      { name: "amount", accessor: "from", align: "left" },
      { name: "command", accessor: "to", align: "left" },
      { name: "req_acc", accessor: "req_acc", align: "left" },
      { name: "req_id", accessor: "req_id", align: "left" },
      { name: "fio", accessor: "fio", align: "left" },
      { name: "started At", accessor: "started At", align: "center" },
      { name: "last activity", accessor: "last activity", align: "center" },
      { name: "status", accessor: "status", align: "right" },
      { name: "actions", accessor: "actions", align: "center" },
    ],
    skeletonRows: generateData(10, {
      uuid: <Skeleton variant="text" sx={{ fontSize: "13px" }} width={40} />,
      amount: <Skeleton variant="text" sx={{ fontSize: "13px" }} width={60} />,
      command: <Skeleton variant="text" sx={{ fontSize: "13px" }} width={60} />,
      pay_uno: <Skeleton variant="text" sx={{ fontSize: "13px" }} width={70} />,
      req_acc: <Skeleton variant="text" sx={{ fontSize: "13px" }} width={60} />,
      req_id: <Skeleton variant="text" sx={{ fontSize: "13px" }} width={60} />,
      fio: <Skeleton variant="text" sx={{ fontSize: "13px" }} width={60} />,
      status: <Skeleton variant="text" sx={{ fontSize: "13px" }} width={70} />,
      ['started At']: <Skeleton variant="text" sx={{ fontSize: "13px" }} width={100} />,
      ['last activity']: <Skeleton variant="text" sx={{ fontSize: "13px" }} width={100} />,
      actions: (
        <SoftBox display="flex" alignItems="center" gap={1}>
          <Skeleton variant="text" sx={{ fontSize: "13px" }} width={70} />
          <Skeleton variant="text" sx={{ fontSize: "13px" }} width={70} />
        </SoftBox>
      ),
    }),
  };
};

export default data;