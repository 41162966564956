import React from "react";
import moment from "moment";
import qs from "qs";
import { Grid, Tab, Tabs } from "@mui/material";
import { getAnalytic } from "http/infoApi";

// React components
import SoftBox from "components/SoftBox";

// React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";

// Data
import { useQuery } from "react-query";
import {
  calculatePercentageChange,
  filterAndFormat,
  getLast30DaysDates,
  generateChartData,
  getDate,
  // getCurrentMonthDates,
} from "layouts/dashboard/helper/chart-helper";

const Dashboard = () => {
  const [sortByTime, setSortByTime] = React.useState("day");
  const [status, setStatus] = React.useState(0);
  const statusTabs = ["All", "Completed", "NmtCheck", "Failed"];

  const [value, setValue] = React.useState(0);
  const handleChange = (__, newValue) => {
    setValue(newValue);
  };
  const { isLoading, data: analytic } = useQuery(["analytic", sortByTime], () =>
    getAnalytic(
      qs.stringify({
        time: sortByTime,
      })
    )
  );

  const getTime = (data, dates) => {
    return dates.map((date) => {
      const targetDate = new Date(date).getTime();
      let type =
        sortByTime === "day"
          ? "hour"
          : sortByTime === "month"
          ? "day"
          : sortByTime === "year"
          ? "month"
          : sortByTime === "all"
          ? "month"
          : "day";

      const dataPoint = data?.find((item) => moment.utc(item[type]).valueOf() === targetDate);

      return dataPoint ? dataPoint.totalAmount : 0;
    });
  };

  const transactionsLineChartData = {
    labels: getDate(sortByTime, analytic?.transaction.firstDate),
    datasets: [
      (statusTabs?.[status] === "Completed" || statusTabs?.[status] === "All") && {
        label: "Completed",
        color: "success",
        data: getTime(
          analytic?.transaction?.completedData,
          getDate(sortByTime, analytic?.transaction.firstDate)
        ),
      },
      (statusTabs?.[status] === "NmtCheck" || statusTabs?.[status] === "All") && {
        label: "NmtCheck",
        color: "warning",
        data: getTime(
          analytic?.transaction?.pendingData,
          getDate(sortByTime, analytic?.transaction.firstDate)
        ),
      },
      (statusTabs?.[status] === "Failed" || statusTabs?.[status] === "All") && {
        label: "Failed",
        color: "error",
        data: getTime(
          analytic?.transaction?.failedData,
          getDate(sortByTime, analytic?.transaction.firstDate)
        ),
      },
    ].filter(Boolean), // Filters out any falsey values (undefined or null datasets)
  };

  console.log(analytic, "analytic");
  const transactionRes = analytic?.transaction?.transactionRes?.data?.[0];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Transacted Amount, Today" }}
                count={transactionRes?.totalAmountForDay}
                currency={true}
                percentage={{ color: "success" }}
                icon={{ color: "warning", component: "payment_icon" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                currency={true}
                title={{ text: "Total Transacted Amount" }}
                count={transactionRes?.totalAmount}
                percentage={{ color: "success" }}
                icon={{ color: "warning", component: "payment_icon" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Total Number of Transactions" }}
                count={transactionRes?.completedCount}
                percentage={{ color: "error" }}
                icon={{ color: "success", component: "emoji_events" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Users" }}
                count={analytic?.user}
                percentage={{ color: "success" }}
                icon={{
                  color: "error",
                  component: "people_icon",
                }}
              />
            </Grid>
          </Grid>
        </SoftBox>

        <SoftBox mb={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} lg={3}>
              <ReportsBarChart
                color="success"
                title={`Successful Transactions`}
                description={
                  calculatePercentageChange(
                    analytic?.transaction?.all?.completedData,
                    analytic?.transaction?.all
                  ) || ""
                }
                date="just updated"
                chart={generateChartData({
                  data: analytic,
                  label: "Successful Tran (%)",
                  color: "success",
                  renderKey: "completedData",
                  type: sortByTime,
                })}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <ReportsBarChart
                color="success"
                title={`Transacted Amount`}
                description={
                  calculatePercentageChange(
                    analytic?.transaction?.all?.successTransacted,
                    analytic?.transaction?.all,
                    "successTransacted"
                  ) || ""
                }
                date="just updated"
                chart={generateChartData({
                  data: analytic,
                  label: "Complited  Tran (%)",
                  color: "success",
                  renderKey: "successTransacted",
                  type: sortByTime,
                })}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <ReportsBarChart
                color="warning"
                title={`NmtCheck Transactions`}
                description={
                  calculatePercentageChange(
                    analytic?.transaction?.all?.pendingData,
                    analytic?.transaction?.all
                  ) || ""
                }
                date="just updated"
                chart={generateChartData({
                  data: analytic,
                  label: "NmtCheck  Tran (%)",
                  color: "warning",
                  renderKey: "pendingData",
                  type: sortByTime,
                })}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <ReportsBarChart
                color="error"
                title={`Failed Transactions`}
                // description={calculatePercentageChange(analytic?.transaction?.failedData)}
                description={
                  calculatePercentageChange(
                    analytic?.transaction?.all?.failedData,
                    analytic?.transaction?.all
                  ) || ""
                }
                date="just updated"
                chart={generateChartData({
                  data: analytic,
                  label: "Failed  Tran (%)",
                  color: "error",
                  renderKey: "failedData",
                  type: sortByTime,
                })}
              />
            </Grid>
          </Grid>
        </SoftBox>

        <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={12}>
              <GradientLineChart
                title="Volume of Transactions"
                filters={
                  <SoftBox display="flex" alignItems="center" justifyContent="space-between" pb={2}>
                    <Tabs value={status} onChange={(___, newValue) => setStatus(newValue)}>
                      {statusTabs.map((tab, index) => (
                        <Tab key={index} label={tab} disableRipple />
                      ))}
                    </Tabs>

                    <Tabs onChange={handleChange} value={value} color="warning">
                      <Tab onClick={() => setSortByTime("day")} label={`DAY`} />
                      <Tab onClick={() => setSortByTime("week")} label="7 DAYS" />
                      <Tab onClick={() => setSortByTime("month")} label="30 DAYS" />
                      <Tab onClick={() => setSortByTime("year")} label="YEAR" />
                      <Tab onClick={() => setSortByTime("all")} label="ALL" />
                    </Tabs>
                  </SoftBox>
                }
                height="auto"
                chart={transactionsLineChartData}
              />
            </Grid>
          </Grid>
        </SoftBox>
      </SoftBox>
    </DashboardLayout>
  );
};

export default Dashboard;
